<template>
  <div
    class="relative !min-w-[40px] w-auto flex --flex-1 !shrink-0 items-center gap-3"
    @mouseenter="showTooltip"
    @mouseleave="hideTooltip"
    @touchstart="showTooltip"
    @touchend="hideTooltip"
  >
    <div class="z-[100]">
      <!-- Slot for the tooltip trigger -->
      <slot></slot>
    </div>

    <!-- Tooltip Content -->
    <div
      v-if="tooltip && tooltip1 && isTooltipVisible"
      class="absolute min-w-[200px] w-auto bg-[#E6FFF0] border-[1px] --border-[#065036] text-[#065036] !z-[1000] text-sm --py-2 --px-3 shadow transition-opacity duration-200"
      :class="tooltipClasses"
    >
      <!-- Tooltip Arrow -->
      <div
        class="absolute w-3 h-3 bg-[#dafde8] border-l border-b --border-[#065036] rotate-45 !z-[1000]"
        :class="arrowClasses"
      ></div>

      <!-- Tooltip Text -->
      <div v-if="tooltip" class="bg-[#dafde8] px-3 py-2">{{ tooltip }}</div>
      <div v-if="tooltip1" class="border-t-[1px] --border-[#065036] px-3 py-2">
        {{ tooltip1 }}
      </div>
      <div v-if="tooltip2" class="border-t-[1px] --border-[#065036] px-3 py-2">
        {{ tooltip2 }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TooltipVue",
  props: {
    tooltip: {
      type: String,
      required: false,
    },
    tooltip1: {
      type: String,
      required: false,
    },
    tooltip2: {
      type: String,
      required: false,
    },
    placement: {
      type: String,
      default: "right", // Default to right with left arrow
      validator: (value) => ["top", "bottom", "left", "right"].includes(value),
    },
  },
  data() {
    return {
      isTooltipVisible: false,
    };
  },
  methods: {
    showTooltip() {
      if (window.innerWidth > 768) {
        // Example for screens larger than 768px
        this.isTooltipVisible = true;
      }
    },
    hideTooltip() {
      if (window.innerWidth > 768) {
        this.isTooltipVisible = false;
      }
    },
    toggleTooltip() {
      this.isTooltipVisible = !this.isTooltipVisible;
      alert(this.isTooltipVisible);
    },
  },
  computed: {
    tooltipClasses() {
      // Ensure tooltip grows downward and aligns properly
      switch (this.placement) {
        case "top":
          return "top-auto bottom-full left-1/2 transform -translate-x-1/2 mt-1";
        case "bottom":
          return "top-full left-1/2 transform -translate-x-1/2 mt-3";
        case "left":
          return "top-0 left-auto right-full transform translate-y-0 ml-3";
        case "right":
          return "top-0 left-full transform translate-y-0 ml-3";
        default:
          return "top-full left-1/2 transform -translate-x-1/2 mt-3";
      }
    },
    arrowClasses() {
      // Position the arrow based on tooltip placement
      switch (this.placement) {
        case "top":
          return "top-auto bottom-[-6px] left-1/2 transform -translate-x-1/2";
        case "bottom":
          return "top-[-6px] left-1/2 transform -translate-x-1/2";
        case "left":
          return "top-1/2 right-[-6px] transform -translate-y-1/2";
        case "right":
          return "top-3 left-[-6px] transform -translate-y-1/2";
        default:
          return "top-[-6px] left-1/2 transform -translate-x-1/2";
      }
    },
  },
};
</script>

<style scoped>
/* Tooltip box styling */
</style>
