<template>
  <div class="w-full h-full flex flex-col -bg-red-500">
    <!-- Nav bar -->
    <div class="w-full h-[50px] border-b bg-[#F8F8F8]">
      <NavBar />
    </div>
    <!-- Main content -->
    <div class="w-full h-[calc(100%-100px)] flex -bg-slate-400">
      <!-- Right bar -->
      <div class="w-[313px] h-full border-r -bg-slate-500">
        <LeftBar :backgroundColor="backgroundColor" />
      </div>
      <!-- Right main Content -->
      <div class="w-[calc(100%-311px)] h-full px-4 -bg-slate-600">
        <!-- <ScannerVue/> -->
        <router-view :backgroundColor="backgroundColor"></router-view>
      </div>
    </div>
    <!-- Footer -->
    <div class="w-full h-[50px] border-t -bg-slate-300">
      <FooterBar />
    </div>
  </div>
</template>

<script>
import NavBar from "../page-layout-components/NavBar.vue";
import LeftBar from "../page-layout-components/LeftBar.vue";
import FooterBar from "../page-layout-components/FooterBar.vue";
// import ScannerVue from "../ScannerVue/ScannerVue.vue";

export default {
  name: "LayoutPage",

  data() {
    return {
      backgroundColor: "", // Default color
    };
  },
  components: {
    NavBar,
    LeftBar,
    FooterBar,
    // ScannerVue,
  },
  mounted() {
    this.updateColorFromUrl();
    // Listen for URL changes if you allow URL updates dynamically
    window.addEventListener("popstate", this.updateColorFromUrl);
  },
  beforeDestroy() {
    // Clean up the event listener
    window.removeEventListener("popstate", this.updateColorFromUrl);
  },
  methods: {
    updateColorFromUrl() {
      // Extract color from the query string
      const urlParams = new URLSearchParams(window.location.search);
      const theme = urlParams.get("theme");

      console.log("theme", theme, urlParams);
      // Validate and update color
      if (theme == "1") {
        this.backgroundColor = "#555F6D";
      } else if (theme == "2") {
        this.backgroundColor = "#008053";
      } else if (theme == "3") {
        this.backgroundColor = "#161616";
      } else if (theme == "4") {
        this.backgroundColor = "#065036";
      } else {
        this.backgroundColor = "#065036";
      }
    },
  },
};
</script>

<style></style>
