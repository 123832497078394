
export const scannerData = {
  GoodStocks: "Good Stocks",
  BadStocks: "Bad Stocks",
  AlmostGoodStocks: "Almost Good Stocks",
  DividendStocks: "Dividend Stocks",
  CoveredCallsCollars: "Covered Calls Collars",
  CurrentTrend: "Current Trend",
  Downsidebreakout: "Downside breakout",
  EarningCalendar: "Earning Calendar",
  EliteCoveredCalls: "Elite Covered Calls",
  OverValuedStocks: "Over Valued Stocks",
  StellarFundamental: "Stellar Fundamental",
  StockUnderTwenty: "Stock Under Twenty",
  ThreeGreenArrows: "Three Green Arrows",
  ThreeRedArrows: "Three Red Arrows",
  CanadianStocks: "Canadian Stocks",
  ETFsScanner: "ETFs Scanner",
  ETFsUptrendScanner: "ETFs Uptrend Scanner",
  UnderValuedStocks: "Under Valued Stocks",
  UpsideBreakout: "Upside Breakout",
};
