import Vue from "vue";
import Router from "vue-router";
// import ScannerVue from "../components/ScannerVue/ScannerVue.vue";
import ScannerVue from "../components/ScannerVue/ScannerVue.vue";
import HelpCenter from "@/components/HelpCenter/HelpCenter.vue";
import ArticleVue from "../components/Article/ArticleVue.vue";
import SettingsVue from "@/components/SettingsVue/SettingsVue.vue";
import Commentary from "@/components/Article/Commentary.vue";
import CoveredCalls from "@/components/Article/CoveredCalls.vue";
import StockPics from "@/components/Article/StockPics.vue";
import Spread from "@/components/Article/Spread.vue";
import LearningCurve from "@/components/Article/LearningCurve.vue";
import Laaps from "@/components/Article/Laaps.vue";
import HomeworkFollowUp from "@/components/Article/HomeworkFollowUp.vue";
import CovalTrendIndicator from "@/components/Article/CovalTrendIndicator.vue";
import TechnicalTalk from "@/components/Article/TechnicalTalk.vue";

Vue.use(Router);

const router = new Router({
  mode: "history", // Clean URLs without hash (#)
  routes: [
    {
      path: "/", // Default route
      name: "ScannerVue",
      component: ScannerVue,
    },
    {
      path: "/overview", // Article page route
      name: "overview",
      component: ArticleVue,
    },
    {
      path: "/commentary", // Article page route
      name: "commentary",
      component: Commentary,
    },
    {
      path: "/coveredcalls", // Article page route
      name: "coveredcalls",
      component: CoveredCalls,
    },
    {
      path: "/stockpicks", // Article page route
      name: "stockpicks",
      component: StockPics,
    },
    {
      path: "/spreads", // Article page route
      name: "spreads",
      component: Spread,
    },
    {
      path: "/learningcurve", // Article page route
      name: "learningcurve",
      component: LearningCurve,
    },
    {
      path: "/leapsplays", // Article page route
      name: "leapsplays",
      component: Laaps,
    },
    {
      path: "/homeworkfollowup", // Article page route
      name: "homeworkfollowup",
      component: HomeworkFollowUp,
    },
    {
      path: "/covaltrendindicator", // Article page route
      name: "covaltrendindicator",
      component: CovalTrendIndicator,
    },
    {
      path: "/technicaltalk", // Article page route
      name: "technicaltalk",
      component: TechnicalTalk,
    },
    {
      path: "/HelpCenter", // HelpCenter page route
      name: "HelpCenter",
      component: HelpCenter,
    },
    {
      path: "/SettingsVue", // SettingsVue page route
      name: "SettingsVue",
      component: SettingsVue,
    },
  ],
});

export default router;
