<template>
  <div class="w-full h-full flex flex-col --py-3 gap-4">
    <div v-if="cardData.length > 0" class="w-full h-full flex flex-col gap-4">
      <div
        v-for="(item, index) in cardData"
        :key="index"
        class="w-full h-[370px] mainContainer flex rounded-xl shadow-md bg-white cardDarkBg dark:border-[#1D1D1D] dark:shadow border-b border-r border-l border-t space-x-4 sm:space-x-8"
      >
        <!-- left side of card -->
        <div class="flex flex-col pt-2 subContainer -bg-green-200 sm:w-[40%]">
          <div class="sm:px-6 px-4 z-10 h-[98px] -bg-green-400">
            <div class="flex items-start space-x-4">
              <p
                class="ticker font-semibold dark:text-[#F6F6F6] text-[#000000] text-[35px] font-inter"
              >
                {{ item?.card?.ticker }}
              </p>

              <span
                v-html="getSvg(item?.card?.shareIcon)"
                class="cursor-pointer dark:text-[#B0B0B0] mt-3 text-[#292929]"
              ></span>
            </div>
            <p
              class="sm:w-[350px] -mt-2.5 truncate font-semibold text-[18px] text-[#000000] dark:text-[#F6F6F6]"
            >
              {{ item?.card?.bName }}
            </p>
            <div class="flex items-center pt-0.5">
              <span
                class="font-semibold text-[16px] text-[#292929] font-inter mr-1 dark:text-[#B0B0B0]"
                >${{ item?.card?.price }}
              </span>

              <span
                class="font-semibold text-[14px] font-inter text-[#037950] dark:text-[#0EBB76]"
                :class="isValue > 0 ? 'text-[#037950]' : 'text-[#FF0000]'"
              >
                {{ item?.card?.change }}
              </span>
              <span
                class="font-semibold ml-1 text-[14px] font-inter dark:text-[#0EBB76] text-[#037950]"
                >{{ item?.card?.cpercent }}
              </span>
            </div>
            <p class="text-[#BDBDBD] -mt-0.5 font-medium text-[10px]">
              {{ item?.card?.updateTime }}
            </p>
            <div
              @click="toggleModal = true"
              class="flex -dark:text-[#CE1717] text-[#4F4F4F] hover:bg-[#e2e2e2] items-center mt-2 py-1.5 cursor-pointer space-x-1.5 sm:w-fit px-2.5 dark:hover:bg-[#e1747419] dark:bg-[#f6838324] justify-center rounded-full bg-[#F6F6F6]"
            >
              <span v-html="getSvg(item?.card?.favIcon)"></span>

              <p class="font-[500] text-[12px]">{{ item?.card?.removeBtn }}</p>
            </div>
          </div>
          <div
            class="flex relative w-full -bg-slate-400 z-0 overflow-hidden rounded-bl-xl h-[280px] -bg-green-500 -border"
          >
            <div class="w-full h-full mt-[0px] -bg-gray-400">
              <!-- <ChartVue /> -->
              <img
                class="w-full h-full object-contain"
                :src="`https://incometradercharts.com/fmgraphttransparent.ashx?h=180&w=600&bw=3&sr=true&s=${item?.card?.ticker}&int=true`"
                alt=""
              />

              <div
                class="absolute w-[50px] h-[50px] bottom-10 left-6 flex items-center justify-center rounded-xl border-2 border-[#d6d6d6] bg-[#F6F6F6] -bg-red-500"
              >
                <span v-html="getSvg(item?.card?.shareIconbtn)"></span>
              </div>
            </div>
            <div class="sm:hidden block">
              <!-- <ChartVue
                :chartWidth="myWidth"
                :chartHeight="SmHeight"
                :chartType="'area'"
              /> -->
            </div>
          </div>
        </div>
        <!-- right side of card -->
        <div
          class="sm:w-[60%] h-full -bg-green-200 subContainer-second -bg-red-200 pt-2 --space-y-4 sm:pr-6 pr-2"
        >
          <div
            class="-bg-green-500 flex justify-between h-[calc(100%-50px)] w-full -bg-yellow-400"
          >
            <div class="flex flex-col w-[48%] justify-center gap-5">
              <div
                class="flex flex-col --pt-4 --pb-1 border-b w-full h-[60px] gap-2 justify-between -bg-red-500"
              >
                <div class="flex w-full h-[50%] justify-between">
                  <span
                    class="text-[#3D3D3D] dark:text-[#F6F6F6] font-[550] text-[12px] font-inter"
                  >
                    {{ item?.card?.cname }}
                  </span>
                  <span v-html="getSvg(item?.card?.rExclamation)"></span>
                </div>
                <div class="flex w-full justify-between">
                  <span
                    class="text-[#3D3D3D] dark:text-[#F6F6F6] font-inter text-[16px]"
                  >
                    {{ item?.card?.name }}
                  </span>
                </div>
              </div>
              <div
                class="flex flex-col --pt-4 --pb-1 border-b w-full h-[60px] gap-2 justify-between -bg-red-500"
              >
                <div class="flex w-full justify-between">
                  <span
                    class="text-[#3D3D3D] dark:text-[#F6F6F6] font-[550] text-[12px] font-inter"
                  >
                    {{ item?.card?.avgVolName }}
                  </span>
                  <span v-html="getSvg(item?.card?.rExclamation)"></span>
                </div>
                <div class="flex w-full justify-between">
                  <span
                    class="text-[#3D3D3D] dark:text-[#F6F6F6] font-inter text-[16px]"
                  >
                    {{ item?.card?.avgVol }}
                  </span>
                </div>
              </div>
              <div
                class="flex flex-col --pt-4 --pb-1 border-b w-full h-[60px] gap-2 justify-between -bg-red-500"
              >
                <div class="flex w-full justify-between">
                  <span
                    class="text-[#3D3D3D] dark:text-[#F6F6F6] font-[550] text-[12px] font-inter"
                  >
                    {{ item?.card?.analPriceName }}
                  </span>
                  <span v-html="getSvg(item?.card?.rExclamation)"></span>
                </div>
                <div class="flex w-full justify-between">
                  <span
                    class="text-[#3D3D3D] dark:text-[#F6F6F6] font-inter text-[16px]"
                  >
                    {{ item?.card?.analPrice }}
                  </span>
                </div>
              </div>
              <div
                class="flex flex-col --pt-4 --pb-1 border-b w-full h-[60px] gap-2 justify-between -bg-red-500"
              >
                <div class="flex w-full justify-between">
                  <span
                    class="text-[#3D3D3D] dark:text-[#F6F6F6] font-[550] text-[12px] font-inter"
                  >
                    {{ item?.card?.nextearnDateName }}
                  </span>
                  <span v-html="getSvg(item?.card?.rExclamation)"></span>
                </div>
                <div class="flex w-full justify-between">
                  <span
                    class="text-[#3D3D3D] dark:text-[#F6F6F6] font-inter text-[16px]"
                  >
                    {{ item?.card?.nextearnDate }}
                  </span>
                </div>
              </div>
            </div>
            <!-- right section -->

            <div class="flex flex-col w-[48%] justify-center gap-5">
              <div
                class="flex flex-col -bg-yellow-300 --pt-4 --pb-1 border-b w-full h-[60px] gap-2 justify-between -bg-red-500"
              >
                <div class="flex w-full justify-between">
                  <span
                    class="text-[#3D3D3D] dark:text-[#F6F6F6] font-[550] text-[12px] font-inter"
                  >
                    {{ item?.card?.sname }}
                  </span>
                  <span v-html="getSvg(item?.card?.rExclamation)"></span>
                </div>
                <div class="flex w-full gap-1 justify-start">
                  <span
                    class="text-[#3D3D3D] dark:text-[#F6F6F6] font-inter text-[16px]"
                  >
                    {{ item?.card?.sector }}
                  </span>
                </div>
              </div>
              <div
                class="flex flex-col -bg-yellow-300 --pt-4 --pb-1 border-b w-full h-[60px] gap-2 justify-between -bg-red-500"
              >
                <div class="flex w-full justify-between">
                  <span
                    class="text-[#3D3D3D] dark:text-[#F6F6F6] font-[550] text-[12px] font-inter"
                  >
                    {{ item?.card?.fairvalName }}
                  </span>
                  <span v-html="getSvg(item?.card?.rExclamation)"></span>
                </div>
                <div class="flex items-center w-full gap-1 justify-start">
                  <span
                    v-if="
                      item?.card?.fairval == null ||
                      item?.card?.analPrice > item?.card?.price
                    "
                    v-html="getSvg(item?.card?.grcheckbtn)"
                  ></span>
                  <span
                    v-if="
                      item?.card?.fairval == null ||
                      item?.card?.analPrice < item?.card?.price
                    "
                    v-html="getSvg('rcrossicon')"
                  ></span>
                  <span
                    :class="
                      item?.card?.alphabet == 'Good Stock'
                        ? '!text-[#039860]'
                        : ''
                    "
                    class="text-[#3D3D3D] dark:text-[#F6F6F6] font-inter text-[16px]"
                  >
                    {{ item?.card?.fairval }}
                  </span>
                </div>
              </div>
              <div
                class="flex flex-col -bg-yellow-300 p--pt-4 --pb-1 border-b w-full h-[60px] gap-2 justify-between -bg-red-500"
              >
                <div class="flex w-full justify-between">
                  <span
                    class="text-[#3D3D3D] dark:text-[#F6F6F6] font-[550] text-[12px] font-inter"
                  >
                    {{ item?.card?.numOfAnalName }}
                  </span>
                  <span v-html="getSvg(item?.card?.rExclamation)"></span>
                </div>
                <div class="flex w-full gap-1 justify-start">
                  <span
                    :class="
                      item?.card?.alphabet == 'Good Stock'
                        ? '!text-[#039860]'
                        : ''
                    "
                    class="text-[#3D3D3D] dark:text-[#F6F6F6] font-inter text-[16px]"
                  >
                    {{ item?.card?.numOfAnalists }}
                  </span>
                </div>
              </div>
              <div
                class="flex flex-col -bg-yellow-300 --pt-4 --pb-1 border-b w-full h-[60px] gap-2 justify-between -bg-red-500"
              >
                <div class="flex w-full justify-between">
                  <span
                    class="text-[#3D3D3D] dark:text-[#F6F6F6] font-[550] text-[12px] font-inter"
                  >
                    {{ item?.card?.stockRatName }}
                  </span>
                  <span v-html="getSvg(item?.card?.rExclamation)"></span>
                </div>
                <div class="flex w-full gap-1 justify-start">
                  <span v-html="getSvg(item?.card?.srIcon)"></span>

                  <span
                    :class="[
                      item?.card?.sRating == 'GoodStock'
                        ? '!text-[#039860]'
                        : '',
                      item?.card?.sRating == 'AlmostGoodStock'
                        ? '!text-[#B3D10B]'
                        : '',
                      item?.card?.sRating == 'AverageStock'
                        ? '!text-[#B3D10B]'
                        : '',
                      item?.card?.sRating == 'BadStock'
                        ? '!text-[#FF0000]'
                        : '',
                    ]"
                    class="dark:text-[#F6F6F6] font-inter text-[16px]"
                  >
                    {{ item?.card?.sRating }}
                  </span>
                </div>
              </div>
            </div>
            <!-- <div
              class="flex items-center space-x-10 py-4 border-b border-dashed dark:border-b-[#292929] border-b-[#E7E7E7]"
            >
              <div
                v-for="(items, keys) in notification"
                class="flex items-center dark:text-[#F6F6F6] text-[#000000] text-[12px] sm:text-[16px] space-x-2"
                :key="keys"
              >
                <svg
                  @click="handleNotification(items?.name)"
                  :class="`cursor-pointer ${
                    selectedNotification?.includes(items?.name)
                      ? 'text-[#039860] '
                      : 'text-[#D1D1D1] dark:text-[#3d3d3d]'
                  }`"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM8 15L3 10L4.41 8.59L8 12.17L15.59 4.58L17 6L8 15Z"
                    fill="currentColor"
                  />
                </svg>
                <p
                  @click="handleNotification(items?.name)"
                  class="cursor-pointer"
                >
                  {{ items.name }}
                </p>
              </div>
            </div> -->
          </div>
          <!-- next sec -->
          <div
            class="flex items-center -pt-3 quoteArea h-[50px] w-full -bg-red-300 justify-end"
          >
            <div
              class="-flex hidden items-center w-fit bg-[#EFEFEF] text-sm hover:bg-[#EFEFEF] px-3 quotebtn cursor-pointer py-1.5 rounded-[8px] justify-center space-x-2"
            >
              <span v-html="getSvg(item?.card?.darrow)"> </span>
              <span class="text-[#000000] text-[16px] font-[500]">{{
                item?.card?.showMoreBtn
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="w-full h-full flex items-center justify-center">
      <h1 class="text-[20px]">No result found!</h1>
    </div>
  </div>
</template>

<script>
// import ChartVue from ".././AppexChart/ChartVue.vue";
import { getSvgByName } from "../../assets/svgs/card";

export default {
  name: "watchlistCard",
  components: {
    // ChartVue,
  },
  props: {
    cardData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      note: "",
      isSave: false,
      loading: false,
      isShowBtn: false,
      isValue: "0.17",
      myWidth: 0,
      myHeight: 250,
      SmHeight: 250,
      notification: [
        { id: 1, name: "SMS" },
        { id: 2, name: "Email" },
        { id: 3, name: "Mobile App" },
      ],
      selectedNotification: ["SMS"],
      scanners: [
        { id: 1, name: "3 Green Arrows", icon: "icon-three-green-arrows.svg" },
        { id: 2, name: "Upside Breakout", icon: "icon-upside-breakout.svg" },
        { id: 3, name: "Under Valued", icon: "icon-under-valued-stocks.svg" },
        { id: 4, name: "3 Red Arrows", icon: "icon-three-red-arrows.svg" },
      ],
      card1Data: [
        {
          id: 1,
          cname: "COMPANY",
          rExclamation: "rExclamation",
          alphabet: "Alphabet IncA",
        },
        {
          id: 2,
          cname: "AVG VOL",
          rExclamation: "rExclamation",
          alphabet: "$29,363,110",
        },
        {
          id: 3,
          cname: "ANALYSTS AVG TARGET PRICE",
          rExclamation: "rExclamation",
          alphabet: "$159.36",
        },
        {
          id: 4,
          cname: "NEXT EARNING DATE",
          rExclamation: "rExclamation",
          alphabet: "09/01/2013",
        },
      ],
      card2Data: [
        {
          id: 1,
          cname: "SECTOR",
          rExclamation: "rExclamation",
          alphabet: "Computer and Technology",
        },
        {
          id: 2,
          cname: "FAIR VALUE",
          rExclamation: "rExclamation",
          grcheckbtn: "grcheckbtn",
          alphabet: "$159.36",
        },
        {
          id: 3,
          cname: "NUMBER OF ANALYSTS",
          rExclamation: "rExclamation",
          alphabet: "36",
        },
        {
          id: 4,
          cname: "STOCK RATING",
          rExclamation: "rExclamation",
          gsIcon: "gsIcon",
          alphabet: "Good Stock",
        },
      ],

      // cardData: [
      //   {
      //     ticker: "IBM",
      //     shareIcon: "share",
      //     shareIconbtn: "shareIconbtn",
      //     bName: "International Buisness Machine",
      //     price: "187.42",
      //     change: "+0,17",
      //     cpercent: "(+1.82%)",
      //     updateTime: "Last updated Yesterday",
      //     favIcon: "favIcon",
      //     removeBtn: "Remove from watchlist",
      //     cname: "COMPANY",
      //     name: "Alphabeticak IncA",
      //     avgVolName: "AVG VOL",
      //     avgVol: "29,363,110",
      //     analPriceName: "ANALYSTS AVG TARGET PRICE",
      //     analPrice: "159.36",
      //     nextearnDateName: "NEXT EARNING DATE",
      //     nextearnDate: "09/01/2013",
      //     sname: "Sector",
      //     sector: "Computer and Technology",
      //     fairvalName: "FAIR VALUE",
      //     grcheckbtn: "grcheckbtn",
      //     fairval: "159.36",
      //     numOfAnalName: "NUMBER OF ANALYSTS",
      //     numOfAnalists: "36",
      //     stockRatName: "STOCK RATING",
      //     srIcon: "gsIcon",
      //     sRating: "Good Stock",
      //     rExclamation: "rExclamation",
      //     darrow: "darrow",
      //     showMoreBtn: "Show More",
      //   },
      //   {
      //     ticker: "IBM",
      //     shareIcon: "shareIcon",
      //     shareIconbtn: "shareIconbtn",
      //     bName: "International Buisness Machine",
      //     price: "187.42",
      //     change: "+0,17",
      //     cpercent: "(+1.82%)",
      //     updateTime: "Last updated Yesterday",
      //     favIcon: "favIcon",
      //     removeBtn: "Remove from watchlist",
      //     cname: "COMPANY",
      //     name: "Alphabeticak IncA",
      //     avgVolName: "AVG VOL",
      //     avgVol: "29,363,110",
      //     analPriceName: "ANALYSTS AVG TARGET PRICE",
      //     analPrice: "159.36",
      //     nextearnDateName: "NEXT EARNING DATE",
      //     nextearnDate: "09/01/2013",
      //     sname: "Sector",
      //     sector: "Computer and Technology",
      //     fairvalName: "FAIR VALUE",
      //     grcheckbtn: "grcheckbtn",
      //     fairval: "159.36",
      //     numOfAnalName: "NUMBER OF ANALYSTS",
      //     numOfAnalists: "36",
      //     stockRatName: "STOCK RATING",
      //     srIcon: "gsIcon",
      //     sRating: "Good Stock",
      //     rExclamation: "rExclamation",
      //     darrow: "darrow",
      //     showMoreBtn: "Show More",
      //   },
      //   {
      //     ticker: "IBM",
      //     shareIcon: "shareIcon",
      //     shareIconbtn: "shareIconbtn",
      //     bName: "International Buisness Machine",
      //     price: "187.42",
      //     change: "+0,17",
      //     cpercent: "(+1.82%)",
      //     updateTime: "Last updated Yesterday",
      //     favIcon: "favIcon",
      //     removeBtn: "Remove from watchlist",
      //     cname: "COMPANY",
      //     name: "Alphabeticak IncA",
      //     avgVolName: "AVG VOL",
      //     avgVol: "29,363,110",
      //     analPriceName: "ANALYSTS AVG TARGET PRICE",
      //     analPrice: "159.36",
      //     nextearnDateName: "NEXT EARNING DATE",
      //     nextearnDate: "09/01/2013",
      //     sname: "Sector",
      //     sector: "Computer and Technology",
      //     fairvalName: "FAIR VALUE",
      //     grcheckbtn: "grcheckbtn",
      //     fairval: "159.36",
      //     numOfAnalName: "NUMBER OF ANALYSTS",
      //     numOfAnalists: "36",
      //     stockRatName: "STOCK RATING",
      //     srIcon: "gsIcon",
      //     sRating: "Good Stock",
      //     rExclamation: "rExclamation",
      //     darrow: "darrow",
      //     showMoreBtn: "Show More",
      //   },
      // ],
      toggleModal: false,
      contentModal: false,
      scannerModal: false,
      screenWidth: window.innerWidth,
    };
  },

  methods: {
    getSvg(name) {
      return getSvgByName(name);
    },
    getTruncateNote() {
      let maxLength = 160;
      if (this.screenWidth < 1351) {
        maxLength = 120;
      }
      if (this.screenWidth < 1155) {
        maxLength = 90;
      }
      if (this.screenWidth < 1000) {
        maxLength = 70;
      }
      if (this.note?.length > maxLength) {
        return this.note?.substring(0, maxLength) + "..";
      }
      return this.note;
    },
    handleSaveNote() {
      if (this.note) {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.isSave = true;
        }, 1000);
      }
    },
    isShowMore() {
      let maxLength = 160;
      if (this.screenWidth < 1351) {
        maxLength = 120;
      }
      if (this.screenWidth < 1155) {
        maxLength = 90;
      }
      if (this.screenWidth < 1000) {
        maxLength = 70;
      }
      if (this.note?.length > maxLength) {
        return true;
      }
    },
    handleEditNote() {
      this.isSave = false;
    },
    updateChartWidth() {
      this.screenWidth = window.innerWidth;
      const containerWidth = this.$refs.chartContainer?.clientWidth;
      console.log("card rows", this.cards);
      this.myWidth = containerWidth;
    },
    handleNotification(name) {
      if (this.selectedNotification.includes(name)) {
        let remove = this.selectedNotification?.filter((item) => item != name);
        this.selectedNotification = remove;
      } else {
        this.selectedNotification.push(name);
      }
    },
    closeModal() {
      this.toggleModal = false;
    },
    closeContentModal() {
      this.contentModal = false;
    },
    closeScannerModal() {
      this.scannerModal = false;
    },
  },
  computed() {
    // this.transformCardData(this.cards);
  },
  mounted() {
    this.updateChartWidth();
    window.addEventListener("resize", this.updateChartWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateChartWidth);
  },
  watch: {},
};
</script>

<style scoped>
.this >>> .v-hl-btn-next svg {
  padding: 4px;
  height: 35px;
  width: 35px;
  color: #7c7c7c;
  background-color: #f6f6f6;
}
.dark .this >>> .v-hl-btn-next svg {
  padding: 4px;
  height: 35px;
  width: 35px;
  color: #cacaca;
  background-color: rgb(72, 72, 72);
}

.this >>> .v-hl-btn-next {
  margin-right: 10px;
}
.this >>> .v-hl-btn-prev {
  margin-left: 10px;
}

.this >>> .v-hl-btn-prev svg {
  padding: 4px;
  height: 35px;
  width: 35px;
  color: #7c7c7c;
  background-color: #f6f6f6;
}

.dark .this >>> .v-hl-btn-prev svg {
  padding: 4px;
  height: 35px;
  width: 35px;
  color: #cacaca;
  background-color: rgb(72, 72, 72);
}

@media screen and (max-width: 1026px) {
  .mainContainer {
    flex-direction: column;
  }
  .subContainer {
    width: 100% !important;
  }
  .subContainer-second {
    width: 100% !important;
    padding-right: 4rem !important;
  }
  .quoteArea {
    padding-bottom: 1rem;
  }
  .quotebtn {
    padding: 8px 14px 8px 14px;
  }
}

@media screen and (max-width: 640px) {
  .quoteArea {
    padding-bottom: 1rem;
  }
  .quotebtn {
    padding: 8px 14px 8px 14px;
  }
  .subContainer-second {
    padding-right: 2.5rem !important;
  }
}
</style>
