import Vue from "vue";
import App from "./App.vue";
import router from "./Router/Router";
import "./index.css"; // Tailwind or global styles
import VTooltip from "vue-directive-tooltip";
// import "vue-directive-tooltip/css/index.css";
import PortalVue from "portal-vue";

// Use plugins
Vue.use(PortalVue);
Vue.use(VTooltip);

new Vue({
  el: "#app",
  router,
  render: (h) => h(App),
});
