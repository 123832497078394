<template>
  <div class="w-full h-full flex justify-between items-center -bg-white px-8">
    <div
      class="gap-8 w-[36%] xl:min-w-[670px] flex justify-between -bg-slate-300"
    >
      <!-- <ToolTipVue content="This is a top tooltip!" placement="bottom"> -->
      <!-- v-tooltip="{ content: 'Custom Tooltip!', class: 'tooltip' }" -->

      <div v-html="getSvg('logo')"></div>
      <!-- </ToolTipVue> -->

      <!-- :class=" ? 'border-[#161616] mrborder-[1.5px]' : ''" -->
      <div
        class="flex border border-[#D1D1D1] min-w-[355px] px-1.5 h-[40px] bg-[#FFFFFF] items-center justify-around rounded"
      >
        <span class="cursor-pointer" v-html="getSvg('search')"> </span>
        <input
          class="w-[70%] !text-[14px] text-[#6D6D6D] py-2 -bg-slate-500 focus:outline-none ring-0"
          type="text"
          placeholder="Search ticker or company name"
        />
        <span v-html="getSvg('command')"> </span>
      </div>
    </div>

    <ul class="flex gap-6" ref="scannerDropdown">
      <li
        v-for="(item, index) in btnData"
        @click="isActivePage(item?.title)"
        :key="index"
        :class="
          item?.title === isActive
            ? 'border-b-2 border-[#037950]'
            : 'border-b-2 border-transparent'
        "
      >
        <router-link
          class="flex cursor-pointer text-[#4F4F4F] leading-[21px] whitespace-nowrap --pb-2 text-[14px] ----font-inter items-center gap-2 h-8"
          :to="{ name: item?.name }"
        >
          <span v-html="getSvg(item?.icon)"></span>
          {{ item?.title }}
          <span v-html="getSvg(item?.dArrow)"></span>
        </router-link>
      </li>
      <!-- scanners dropdown -->
      <div
        v-if="scansDropdown"
        class="overflow-y-auto dropdown w-[250px] h-[400px] absolute bg-[#F8F8F8] rounded-bl rounded-br border shadow-xl z-[1000] top-12"
      >
        <ul class="relative" @mouseleave="unSelectArticleType()">
          <li
            v-for="(item, index) in scanTypeData"
            :key="index"
            @click="isActiveScan(item?.name, item.title)"
            @mouseover="selectArticleType(item?.name, item.title)"
          >
            <router-link
              class="flex cursor-pointer border-t px-4 hover:bg-[#E5F3EC] whitespace-nowrap text-[#555F6D] ----font-inter text-[13px] --font-thin items-center gap-4 h-12"
              :class="
                item?.title == 'ETFs Scanner' ||
                item?.title == 'ETFs Uptrend Scanner'
                  ? 'flex justify-between'
                  : ''
              "
              :to="{ name: item?.pagename }"
            >
              <div
                class="flex cursor-pointer border-t --px-4 hover:bg-[#E5F3EC] whitespace-nowrap text-[#555F6D] ----font-inter text-[13px] --font-thin items-center gap-4 h-12"
              >
                <span
                  class="!h-[20px] !w-[20px]"
                  v-html="getSvg(item.icon)"
                ></span>

                {{ item?.title }}
              </div>
              <span
                class="align-end"
                v-if="
                  item?.title == 'ETFs Scanner' ||
                  item?.title == 'ETFs Uptrend Scanner'
                "
                v-html="getSvg('rarrow')"
              ></span>
            </router-link>
          </li>
          <!-- ETFs Scanner -->
          <div
            v-if="isETFsScanner"
            class="w-[250px] h-auto ml-[247px] rounded-bl rounded-br border shadow-xl top-[13rem] bg-[#F8F8F8] fixed"
          >
            <ul>
              <li
                v-for="(item, index) in ETFsScanner"
                :key="index"
                @click="isActiveScan(item?.name, item.title)"
              >
                <router-link
                  class="flex -justify-between cursor-pointer border-t px-4 hover:bg-[#E5F3EC] whitespace-nowrap text-[#555F6D] ----font-inter text-[13px] --font-thin items-center gap-4 h-12"
                  :to="{ name: item?.pagename }"
                >
                  <span class="!h-5 !w-5" v-html="getSvg(item.icon)"></span>
                  {{ item?.title }}
                </router-link>
              </li>
            </ul>
          </div>

          <!-- ETFs Uptrend Scanner -->
          <div
            v-if="isETFsUptrendScanner"
            class="w-[250px] h-auto ml-[247px] z-[999] rounded-bl rounded-br border shadow-xl top-[16rem] bg-[#F8F8F8] fixed"
          >
            <ul>
              <li
                v-for="(item, index) in ETFsUptrendScanner"
                :key="index"
                @click="isActiveScan(item?.name, item.title)"
              >
                <router-link
                  class="flex -justify-between cursor-pointer border-t px-4 hover:bg-[#E5F3EC] whitespace-nowrap text-[#555F6D] ----font-inter text-[13px] --font-thin items-center gap-4 h-12"
                  :to="{ name: item?.pagename }"
                >
                  <span class="h-5 w-5" v-html="getSvg(item.icon)"></span>
                  {{ item?.title }}
                </router-link>
              </li>
            </ul>
          </div>
        </ul>
      </div>
      <!-- articles dropdown -->
      <div
        v-if="articlesDropdown"
        ref="box"
        class="h-auto w-[250px] ml-[137px] absolute bg-[#F8F8F8] rounded-bl rounded-br border shadow-xl z-[1000] top-12"
      >
        <ul class="relative" @mouseleave="unSelectArticleType()">
          <li
            v-for="(item, index) in articleType"
            :key="index"
            @mouseover="selectArticleType(item?.name, item.title)"
            class="flex justify-between cursor-pointer border-t px-4 hover:bg-[#E5F3EC] whitespace-nowrap text-[#555F6D] ----font-inter text-[13px] ----font-thin items-center gap-4 h-12"
          >
            <!-- article type -->
            <div class="flex items-center gap-2">
              <span class="h-5 w-5" v-html="getSvg(item.icon)"></span>
              {{ item?.title }}
            </div>
            <span v-html="getSvg('rarrow')"></span>
          </li>

          <!-- current article -->
          <div
            v-if="currentarticle"
            class="w-full h-auto ml-[249px] rounded-bl rounded-br border shadow-xl top-0 bg-[#F8F8F8] absolute"
          >
            <ul>
              <li
                v-for="(item, index) in currentarticlesData"
                :key="index"
                @click="isActiveScan(item?.name, item.title)"
              >
                <router-link
                  class="flex -justify-between cursor-pointer border-t px-4 hover:bg-[#E5F3EC] whitespace-nowrap text-[#555F6D] --font-inter text-[13px] --font-thin items-center gap-4 h-12"
                  :to="{ name: item?.pagename }"
                >
                  <span class="!h-5 !w-5" v-html="getSvg(item.icon)"></span>
                  {{ item?.title }}
                </router-link>
              </li>
            </ul>
          </div>

          <!-- past article -->
          <div
            v-if="pastarticle"
            class="w-full h-auto ml-[249px] rounded-bl rounded-br border shadow-xl top-10 bg-[#F8F8F8] absolute"
          >
            <ul>
              <li
                v-for="(item, index) in pastarticlesData"
                :key="index"
                @click="isActiveScan(item?.name, item.title)"
              >
                <router-link
                  class="flex -justify-between cursor-pointer border-t px-4 hover:bg-[#E5F3EC] whitespace-nowrap text-[#555F6D] --font-inter text-[13px] --font-thin items-center gap-4 h-12"
                  :to="{ name: item?.pagename }"
                >
                  <span class="h-5 w-5" v-html="getSvg(item.icon)"></span>
                  {{ item?.title }}
                </router-link>
              </li>
            </ul>
          </div>
        </ul>
      </div>
    </ul>
  </div>
</template>

<script>
import { getSvgByName } from "../../assets/svgs/Navbar.js";
import { scannerData } from "../ScannerName.js";
import ChartEvent from "../../EventBus.js";
// import ToolTipVue from "../ToolTipVue/TooltipVue.vue";

export default {
  name: "NavBar",
  data() {
    return {
      isActive: "Scanners",
      isEnter: false,
      dropdownRef: null,
      scansDropdown: false,
      articlesDropdown: false,
      isActiveScanner: "GoodStocks",
      currentarticle: false,
      isETFsUptrendScanner: false,
      isETFsScanner: false,
      pastarticle: false,
      btnData: [
        {
          id: 1,
          title: "Scanners",
          icon: "magic",
          dArrow: "dArrow",
        },
        {
          id: 2,
          title: "Articles",
          icon: "article",
          dArrow: "dArrow",
        },
        {
          id: 3,
          title: "Help Center",
          name: "HelpCenter",
          icon: "exclamation",
        },
        {
          id: 4,
          title: "Settings",
          name: "SettingsVue",
          icon: "settings",
        },
        {
          id: 5,
          title: "Sign Out",
          icon: "logout",
        },
      ],
      scanTypeData: [
        {
          id: 1,
          title: scannerData.ThreeGreenArrows,
          name: scannerData.ThreeGreenArrows.replace(/ /g, ""),
          pagename: "ScannerVue",
          icon: scannerData.ThreeGreenArrows.replace(/ /g, ""),
        },
        {
          id: 2,
          title: scannerData.ThreeRedArrows,
          name: scannerData.ThreeRedArrows.replace(/ /g, ""),
          pagename: "ScannerVue",
          icon: scannerData.ThreeRedArrows.replace(/ /g, ""),
        },
        {
          id: 3,
          title: scannerData.UpsideBreakout,
          name: scannerData.UpsideBreakout.replace(/ /g, ""),
          pagename: "ScannerVue",
          icon: scannerData.UpsideBreakout.replace(/ /g, ""),
        },
        {
          id: 4,
          title: scannerData.Downsidebreakout,
          name: scannerData.Downsidebreakout.replace(/ /g, ""),
          pagename: "ScannerVue",
          icon: scannerData.Downsidebreakout.replace(/ /g, ""),
        },
        {
          id: 5,
          title: scannerData.CurrentTrend,
          name: scannerData.CurrentTrend.replace(/ /g, ""),
          pagename: "ScannerVue",
          icon: scannerData.CurrentTrend.replace(/ /g, ""),
        },
        {
          id: 6,
          title: scannerData.GoodStocks,
          name: scannerData.GoodStocks.replace(/ /g, ""),
          pagename: "ScannerVue",
          icon: scannerData.GoodStocks.replace(/ /g, ""),
        },
        {
          id: 7,
          title: scannerData.AlmostGoodStocks,
          name: scannerData.AlmostGoodStocks.replace(/ /g, ""),
          pagename: "ScannerVue",
          icon: scannerData.AlmostGoodStocks.replace(/ /g, ""),
        },
        {
          id: 7,
          title: scannerData.BadStocks,
          name: scannerData.BadStocks.replace(/ /g, ""),
          pagename: "ScannerVue",
          icon: scannerData.BadStocks.replace(/ /g, ""),
        },

        {
          id: 8,
          title: scannerData.DividendStocks,
          name: scannerData.DividendStocks.replace(/ /g, ""),
          pagename: "ScannerVue",
          icon: scannerData.DividendStocks.replace(/ /g, ""),
        },
        {
          id: 9,
          title: scannerData.UnderValuedStocks,
          name: scannerData.UnderValuedStocks.replace(/ /g, ""),
          pagename: "ScannerVue",
          icon: scannerData.UnderValuedStocks.replace(/ /g, ""),
        },
        {
          id: 10,
          title: scannerData.OverValuedStocks,
          name: scannerData.OverValuedStocks.replace(/ /g, ""),
          pagename: "ScannerVue",
          icon: scannerData.OverValuedStocks.replace(/ /g, ""),
        },
        {
          id: 11,
          title: scannerData.StockUnderTwenty,
          name: scannerData.StockUnderTwenty.replace(/ /g, ""),
          pagename: "ScannerVue",
          icon: scannerData.StockUnderTwenty.replace(/ /g, ""),
        },
        {
          id: 12,
          title: scannerData.StellarFundamental,
          name: scannerData.StellarFundamental.replace(/ /g, ""),
          pagename: "ScannerVue",
          icon: scannerData.StellarFundamental.replace(/ /g, ""),
        },
        {
          id: 13,
          title: scannerData.CanadianStocks,
          name: scannerData.CanadianStocks.replace(/ /g, ""),
          pagename: "ScannerVue",
          icon: scannerData.CanadianStocks.replace(/ /g, ""),
        },
        {
          id: 14,
          title: scannerData.ETFsScanner,
          pagename: "ScannerVue",
          icon: scannerData.ETFsScanner.replace(/ /g, ""),
        },
        {
          id: 15,
          title: scannerData.ETFsUptrendScanner,
          pagename: "ScannerVue",
          icon: scannerData.ETFsUptrendScanner.replace(/ /g, ""),
        },

        {
          id: 16,
          title: scannerData.EarningCalendar,
          name: scannerData.EarningCalendar.replace(/ /g, ""),
          pagename: "ScannerVue",
          icon: scannerData.EarningCalendar.replace(/ /g, ""),
        },
        {
          id: 17,
          title: scannerData.EliteCoveredCalls,
          name: scannerData.EliteCoveredCalls.replace(/ /g, ""),
          pagename: "ScannerVue",
          icon: scannerData.EliteCoveredCalls.replace(/ /g, ""),
        },
        {
          id: 18,
          title: scannerData.CoveredCallsCollars,
          name: scannerData.CoveredCallsCollars.replace(/ /g, ""),
          pagename: "ScannerVue",
          icon: scannerData.CoveredCallsCollars.replace(/ /g, ""),
        },
      ],
      ETFsScanner: [
        {
          title: "Bond ETFs",
          name: "BondETFs",
          icon: "BondETFs",
        },
        {
          title: "Equity ETFs",
          name: "EquityETFs",
          icon: "EquityETFs",
        },
        {
          title: "Income Fund ETFs",
          name: "IncomeFundETFs",
          icon: "IncomeFundETFs",
        },
        {
          title: "Intl Equity ETFs",
          name: "IntlEquityETFs",
          icon: "IntlEquityETFs",
        },
        {
          title: "Popular ETFs",
          name: "PopularETFs",
          icon: "PopularETFs",
        },
        {
          title: "Reit ETFs",
          name: "ReitETFs",
          icon: "ReitETFs",
        },
      ],
      ETFsUptrendScanner: [
        {
          title: "Equity ETFs",
          name: "EquityETFs",
          icon: "EquityETFs",
        },
        {
          title: "Income Fund ETFs",
          name: "IncomeFundETFs",
          icon: "IncomeFundETFs",
        },
        {
          title: "Intl Equity ETFs",
          name: "IntlEquityETFs",
          icon: "IntlEquityETFs",
        },
        {
          title: "Popular ETFs",
          name: "PopularETFs",
          icon: "PopularETFs",
        },
        {
          title: "Reit ETFs",
          name: "ReitETFs",
          icon: "ReitETFs",
        },
      ],
      articleType: [
        {
          id: 1,
          title: "Current Articles",
          icon: "currentarticleicon",
        },
        {
          id: 2,
          title: "Past Articles",
          icon: "pastarticleicon",
        },
      ],
      currentarticlesData: [
        {
          id: 1,
          title: "Overview",
          icon: "overview",
          pagename: "overview",
        },
        {
          id: 2,
          title: "Commentary",
          icon: "commentary",
          pagename: "commentary",
        },
        {
          id: 3,
          title: "Covered Calls",
          icon: "CoveredCallsCollars",
          pagename: "coveredcalls",
        },
        {
          id: 4,
          title: "Stock Picks",
          icon: "stockpicks",
          pagename: "stockpicks",
        },
        {
          id: 5,
          title: "Spreads",
          icon: "spreads",
          pagename: "spreads",
        },
        {
          id: 6,
          title: "Learning Curve",
          icon: "learningcurve",
          pagename: "learningcurve",
        },
        {
          id: 7,
          title: "LEAPS Plays",
          icon: "leapsplays",
          pagename: "leapsplays",
        },
        {
          id: 8,
          title: "Homework Followup",
          icon: "homeworkfollowup",
          pagename: "homeworkfollowup",
        },
        {
          id: 9,
          title: "Coval Trend Indicator",
          icon: "covaltrendindicator",
          pagename: "covaltrendindicator",
        },
        {
          id: 10,
          title: "Technical Talk",
          icon: "technicaltalk",
          pagename: "technicaltalk",
        },
      ],
      pastarticlesData: [
        {
          id: 1,
          title: "Technical Talk",
          icon: "technicaltalk",
          pagename: "ArticleVue",
        },
        {
          id: 1,
          title: "Learning Curve",
          icon: "learningcurve",
          pagename: "ArticleVue",
        },
        {
          id: 1,
          title: "Glossary",
          icon: "glossary",
          pagename: "ArticleVue",
        },
      ],
    };
  },

  components: {
    // ToolTipVue,
  },
  mounted() {
    this.$nextTick(() => {
      document.addEventListener("click", this.handleOutsideClick);
    });
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleOutsideClick);
  },
  methods: {
    getSvg(name) {
      return getSvgByName(name);
    },
    handleOutsideClick(event) {
      if (
        this.$refs.scannerDropdown &&
        !this.$refs.scannerDropdown.contains(event.target)
      ) {
        this.scansDropdown = false;
        this.articlesDropdown = false;
      }
    },
    hideScrollbar() {
      document.querySelector(".dropdown").classList.add("scroll-hidden");
    },
    showScrollbar() {
      document.querySelector(".dropdown").classList.remove("scroll-hidden");
    },
    isActivePage(name) {
      this.isActive = name;
      if (name === "Scanners") {
        this.scansDropdown = !this.scansDropdown;
        this.articlesDropdown = false;
      } else if (name === "Articles") {
        this.articlesDropdown = !this.articlesDropdown;
        this.scansDropdown = false;
      }
    },
    selectArticleType(name, title) {
      if (title === "Current Articles") {
        console.log("title", title);
        // alert(title);
        this.currentarticle = true;
        this.pastarticle = false;
      } else if (title === "Past Articles") {
        this.pastarticle = true;
        this.currentarticle = false;
      } else if (title === "ETFs Scanner") {
        this.isETFsScanner = true;
        this.isETFsUptrendScanner = false;
        this.hideScrollbar();
      } else if (title === "ETFs Uptrend Scanner") {
        this.isETFsUptrendScanner = true;
        this.isETFsScanner = false;
        this.hideScrollbar();
      } else if (title !== "ETFs Scanner" || title !== "ETFs Uptrend Scanner") {
        this.isETFsScanner = false;
        this.isETFsUptrendScanner = false;

        this.showScrollbar();
      }
    },

    handleDocumentClick(event) {
      const box = this.$refs.box;
      if (box && !box.contains(event.target)) {
        // Check if `box` exists before accessing `.contains`
        this.isEnter = false;
        // console.log("box is clicked1");
      } else {
        this.isEnter = true;
        // console.log("box is clicked2");
      }
    },

    unSelectArticleType() {
      this.currentarticle = false;

      this.pastarticle = false;
      this.isETFsScanner = false;
      this.isETFsUptrendScanner = false;
    },

    // isActiveScan(name, title) {
    //   console.log("name, title", name, title);
    //   if (!name || !title) {
    //     console.error("Invalid parameters for Active Scan");
    //   } else {
    //     console.log("name, title after", name, title);
    //   }
    // },

    isActiveScan(name, title) {
      console.log("Emitting isActiveScan event with:", name, title);
      if (!name || !title) {
        console.error("Invalid parameters for Active Scan");
        return;
      }
      this.isActiveScanner = name;
      this.scansDropdown = false;
      this.articlesDropdown = false;

      ChartEvent.$emit("isActiveScan", this.isActiveScanner, title);
    },
  },
};
</script>

<style scoped>
.tooltip {
  background-color: blue;
}
</style>
