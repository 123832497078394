<template>
    <div class="w-full h-full flex flex-col px-10 py-5 gap-5 overflow-y-auto">
      <div class="flex justify-end items-center gap-6 h-16">
        <div
          class="font-[500] border px-4 py-1 flex bg-[#EAEDF0] items-center rounded-full gap-2"
        >
          <span
            @click="smallFont"
            class="cursor-pointer"
            v-html="getSvg('subtraction')"
          />
  
          Font size
  
          <span
            class="cursor-pointer"
            v-html="getSvg('addition')"
            @click="largeFont"
          />
        </div>
        <div
          class="cursor-pointer font-[500] border px-4 py-1 flex bg-[#EAEDF0] items-center rounded-full gap-2"
        >
          Jump to section<span v-html="getSvg('darrow')"></span>
        </div>
      </div>
      <div class="flex flex-col self-center w-[50rem] h-[200rem] gap-5">
        <div class="w-[85%] flex items-center justify-between">
          <div class="flex flex-col">
            <span class="font-bold font-inter text-[#656565] text-[14px]">{{
              activeArticle.category.toUpperCase()
            }}</span>
            <div class="w-[25px] h-[5px] bg-[#656565]"></div>
          </div>
          <!-- <div
                @click="showArticle = false"
                class="flex items-center gap-1 text-[12px] hover:text-black cursor-pointer"
              >
                Stop reading
              </div> -->
        </div>
  
        <div class="w-[85%] h-auto flex flex-col justify-center items-start">
          <h1 class="font-[550] leading-[35px] text-[35px] font-inter">
            {{ activeArticle.title }}
          </h1>
        </div>
  
        <div
          class="w-full h-auto flex flex-col justify-center items-start -bg-red-500"
        >
          <p
            v-if="articlText"
            :style="{ fontSize: fontSize + 'px' }"
            :class="`leading-[28px] tracking-[0.5px] font-inter text-[#4F4F4F]`"
          >
            {{
              articlText.split(".")[0] +
              ". " +
              articlText.split(".")[1] +
              ". " +
              articlText.split(".")[2] +
              ". "
            }}
          </p>
          <p v-else>Loading article text...</p>
        </div>
  
  
        <div
              class="w-[85%] h-[100px] flex flex-col border-b justify-center items-start -bg-red-500"
            >
              <div
                class="w-full h-20 flex -justify-center items-center gap-10 -bg-red-500"
              >
                <div
                  class="flex items-center gap-2 text-[#3D3D3D] font-[500] text-[16px] leading-[18px] font-inter"
                >
                  <span v-html="getSvg('calender')"></span>
                  <span>{{ setDate(activeArticle.date) }}</span>
                </div>
  
                <div
                  class="flex items-center gap-2 text-[#3D3D3D] font-[500] text-[16px] leading-[18px] font-inter"
                >
                  <img
                    class="rounded-full h-7 w-7"
                    :src="activeArticle.authorImage"
                  />
                  <span>Published by {{ activeArticle.author }}</span>
                </div>
  
                <div
                  class="flex items-center gap-2 text-[#3D3D3D] font-[500] text-[16px] leading-[18px] font-inter"
                >
                  <span>5 min read</span>
                </div>
              </div>
            </div>
            <div
              class="w-[85%] h-[100px] flex border-b justify-between items-center -bg-red-500"
            >
              <div
                class="w-auto h-20 flex -justify-center items-center gap-10 -bg-red-500"
              >
                <div
                  v-if="!isPlaying && !isPaused"
                  @click="speak(activeArticle.description)"
                  class="flex items-center gap-2 text-[#CE1717] cursor-pointer px-5 py-3 bg-[#CE171714] font-[500] text-[16px] rounded-full leading-[18px] font-inter"
                >
                  <span v-html="getSvg('playbtnicon')"></span>
                  <span>Play this article</span>
                </div>
  
                <div
                  v-else
                  class="flex items-center gap-2 text-[#CE1717] cursor-pointer px-5 py-2 bg-[#CE171714] font-[500] text-[16px] rounded-full leading-[18px] font-inter"
                >
                  <div class="flex gap-3">
                    <div
                      class="flex items-center cursor-pointer gap-1 w-[5rem]"
                      v-if="isPaused"
                      @click="resumeSpeech"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="size-6 stroke-[#1fba1f]"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M21 7.5V18M15 7.5V18M3 16.811V8.69c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061A1.125 1.125 0 0 1 3 16.811Z"
                        />
                      </svg>
                      <button
                        class="text-[#1fba1f] text-[13px]"
                        :disabled="!isPaused"
                      >
                        Resume
                      </button>
                    </div>
                    <div
                      v-else
                      class="flex items-center cursor-pointer gap-1 w-[5rem]"
                      @click="pauseSpeech"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="size-6 stroke-[#b7b7b7]"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M21 7.5V18M15 7.5V18M3 16.811V8.69c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061A1.125 1.125 0 0 1 3 16.811Z"
                        />
                      </svg>
  
                      <button
                        class="text-[#b7b7b7] text-[13px]"
                        :disabled="!isPlaying"
                      >
                        Pause
                      </button>
                    </div>
                    <div
                      class="flex items-center cursor-pointer gap-1"
                      @click="stopSpeech"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        class="size-4 stroke-[#CE1717] fill-none stroke-[3px]"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.5 7.5a3 3 0 0 1 3-3h9a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3h-9a3 3 0 0 1-3-3v-9Z"
                          clip-rule="evenodd"
                        />
                      </svg>
  
                      <button
                        class="text-[#CE1717] text-[13px]"
                        :disabled="!isPlaying && !isPaused"
                      >
                        Stop
                      </button>
                    </div>
                  </div>
                </div>
  
                <div
                  class="flex items-center gap-2 text-[#037950] cursor-pointer px-5 py-3 font-[500] text-[16px] bg-[#03795014] rounded-full leading-[18px] font-inter"
                >
                  <span v-html="getSvg('printbtnicon')"></span>
                  <span>Print this article</span>
                </div>
              </div>
  
              <div
                class="w-auto h-20 flex -justify-center items-center gap-10 -bg-red-500"
              >
                <div
                  @click="favArticle"
                  class="flex items-center gap-2 text-[#CE1717] cursor-pointer px-2.5 py-2.5 bg-[#F9F9F9] hover:bg-[#e8e5e5]/50 font-[500] text-[16px] rounded-lg leading-[18px] font-inter"
                >
                  <span
                    class="h-5 w-4"
                    :class="
                      isFav
                        ? 'stroke-[#FFC645] fill-[#FFC645]'
                        : 'fill-none stroke-gray-400'
                    "
                    v-html="getSvg('copybtnicon')"
                  ></span>
                </div>
  
                <!-- <div
                  class="flex items-center gap-2 text-[#037950] cursor-pointer px-2.5 py-2.5 font-[500] text-[16px] bg-[#F9F9F9] rounded-lg leading-[18px] font-inter"
                >
                  <span v-html="getSvg('uploadbtnicon')"></span>
                </div> -->
              </div>
            </div>
  
            <div
              class="flex h-[20rem] w-[40rem] overflow-hidden mt-10 rounded-md bg-green-400"
            >
              <img
                :src="
                  articleImages?.length > 0
                    ? articleImages[0]
                    : activeArticle.image
                "
                class="object-fill w-full"
              />
            </div>
  
            <div
              class="w-[85%] h-auto flex -border-b justify-between items-center -bg-red-500"
            >
              <div
                class="w-auto h-auto flex flex-col py-14 -justify-center items-center gap-10 -bg-red-500"
              >
                <p
                  :style="{ fontSize: fontSize + 'px' }"
                  :class="` leading-[28px] tracking-[0.5px] font-inter text-[#4F4F4F]`"
                >
                  {{ remaininText }}
                </p>
              </div>
            </div>
  
  
            <div
              v-if="articleImages[1]"
              class="flex h-[20rem] w-[40rem] overflow-hidden rounded-md bg-green-400"
            >
              <img :src="articleImages[1]" class="object-fill w-full" />
            </div>
            <div
              v-if="articleImages[2]"
              class="flex h-[20rem] w-[40rem] mt-10 overflow-hidden rounded-md bg-green-400"
            >
              <img :src="articleImages[2]" class="object-fill w-full" />
            </div>
            <div
              v-if="articleImages[3]"
              class="flex h-[20rem] w-[40rem] mt-10 overflow-hidden rounded-md bg-green-400"
            >
              <img :src="articleImages[3]" class="object-fill w-full" />
            </div>
  
            <div
              v-if="articleImages[4]"
              class="flex h-[20rem] w-[40rem] mt-10 overflow-hidden rounded-md bg-green-400"
            >
              <img :src="articleImages[4]" class="object-fill w-full" />
            </div>
            <div
              class="w-[85%] h-[100px] flex border-b justify-between items-center -bg-red-500"
            >
              <div
                class="w-auto h-20 flex -justify-center items-center gap-14 -bg-red-500"
              >
                <div
                  class="flex items-center gap-2 text-[#000000]-bg-[#CE171714] font-[500] text-[16px] -rounded-full leading-[18px] font-inter"
                >
                  <span v-html="getSvg('DavidMcKinlay')"></span>
                  <div class="flex flex-col justify-center">
                    <span>David McKinlay</span>
                    <span
                      class="text-[#888888] text-[14px] font-[500] leading-[16px]"
                      >Associate Editor</span
                    >
                  </div>
                </div>
  
                <div
                  class="flex items-center gap-2 text-[#000000] font-[500] text-[16px] -bg-[#03795014] -rounded-full leading-[18px] font-inter"
                >
                  <span v-html="getSvg('MikeCoval')"></span>
                  <div class="flex flex-col justify-center">
                    <span>Mike Coval</span>
                    <span
                      class="text-[#888888] text-[14px] font-[500] leading-[16px]"
                      >Editor</span
                    >
                  </div>
                </div>
              </div>
            </div>
  
      </div>
    </div>
  </template>
  
  <script>
  import { singleArticl } from "./Articls";
  import { getSvgByName } from "../../assets/svgs/article";
  import * as base64 from "base64-js";
  import moment from "moment";
  export default {
    name:'LEAPS',
    data() {
      return {
        activeArticle: singleArticl,
        decodedDescription: "",
        //   activeArticle: null,
        articlText: "",
        remaininText: "",
        articleImages: null,
        isFav: false,
        isPlaying: false,
        isPaused: false,
        fontSize: 18,
      };
    },
    methods: {
      smallFont() {
        console.log("ee", this.articlText, this.remaininText);
        if (this.fontSize > 18) {
          this.fontSize -= 2; // Decrease by 2px
        }
        console.log("font", this.fontSize);
      },
      largeFont() {
        if (this.fontSize < 28) {
          this.fontSize += 2; // Increase by 2px
        }
        console.log("font", this.fontSize);
      },
      favArticle() {
        this.isFav = !this.isFav;
      },
      setDate(val) {
        return moment(val).format("dddd, Do [of] MMMM, YYYY");
      },
      getSvg(name) {
        return getSvgByName(name);
      },
      // updateShowArticle() {
      // //   this.activeArticle = item;
      // //   this.showArticle = true;
      //   this.decodedDescription = this.decodeBase64(this.activeArticle.description);
      // },
      decodeBase64() {
        let decodedArray = base64.toByteArray(this.activeArticle.description); // Decodes to a Uint8Array
        let decodedString = new TextDecoder().decode(decodedArray); // Converts to string
  
        let parser = new DOMParser();
        let doc = parser.parseFromString(decodedString, "text/html");
  
        // Extract image sources
        //   let imgSources = Array.from(doc.querySelectorAll("img")).map(
        //     (img) => img.src
        //   );
  
        let imgSources = Array.from(doc.querySelectorAll("img"))
          .map((img) => img.src)
          .filter((src) => src && src !== ""); // Filter out invalid or empty image sources
  
        console.log("extractedImages", imgSources);
        this.articleImages = imgSources;
  
        // Split text into sentences
        let exacerbText = doc.body.textContent.split(".");
  
        // Set the first few sentences as articlText
        if (exacerbText.length < 3) {
          this.articlText = doc.body.textContent;
          setTimeout(() => {
      this.articlText = doc.body.textContent;
    }, 2000);
        } else {
          setTimeout(() => {
              this.articlText = exacerbText.slice(0, 3).join(".") + "."; // Keep only the first 3 sentences
    }, 2000);
          // this.articlText = exacerbText.slice(0, 3).join(".") + "."; // Keep only the first 3 sentences
        }
  
        // Set remaining text
        this.remaininText = exacerbText.slice(3).join(".").trim();
  
        // Ensure remaininText ends with a full stop
        if (this.remaininText) {
          this.remaininText += ".";
        }
  
        // Return the decoded string (not used elsewhere)
        return decodedString;
      },
      speak(val) {
        let decodedArray = base64.toByteArray(val); // Decodes to a Uint8Array
        let decodedString = new TextDecoder().decode(decodedArray); // Converts to string
  
        // Create a temporary DOM element to parse the HTML
        let parser = new DOMParser();
        let doc = parser.parseFromString(decodedString, "text/html");
  
        // Extract text content without any HTML tags
        let plainText = doc.body.textContent || "";
  
        if ("speechSynthesis" in window) {
          // Cancel any ongoing speech
          window.speechSynthesis.cancel();
  
          const utterance = new SpeechSynthesisUtterance(plainText);
          utterance.lang = "en-US";
          utterance.pitch = 1.4;
          utterance.rate = 0.9;
          utterance.volume = 1;
  
          // Select a female voice
          let voices = window.speechSynthesis.getVoices();
          // console.log('voices',voices)
          let femaleVoice = voices.find((voice) => voice.name.includes("Martha"));
  
          if (femaleVoice) {
            utterance.voice = femaleVoice;
          }
  
          // Event listeners
          utterance.onstart = () => {
            this.isPlaying = true;
            this.isPaused = false;
          };
  
          utterance.onend = () => {
            this.isPlaying = false;
            this.isPaused = false;
          };
  
          utterance.onpause = () => {
            this.isPlaying = false;
            this.isPaused = true;
          };
  
          utterance.onresume = () => {
            this.isPlaying = true;
            this.isPaused = false;
          };
  
          // Speak the text
          window.speechSynthesis.speak(utterance);
        } else {
          console.error("Your browser does not support the Web Speech API");
        }
      },
      pauseSpeech() {
        if ("speechSynthesis" in window && window.speechSynthesis.speaking) {
          window.speechSynthesis.pause();
        }
      },
  
      // Resume the speech
      resumeSpeech() {
        if ("speechSynthesis" in window && window.speechSynthesis.paused) {
          window.speechSynthesis.resume();
        }
      },
  
      // Stop the speech
      stopSpeech() {
        if ("speechSynthesis" in window) {
          window.speechSynthesis.cancel();
          this.isPlaying = false;
          this.isPaused = false;
        }
      },
    },
    mounted(){
     this.decodeBase64() 
    }
  };
  </script>
  