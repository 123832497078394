export function getSvgByName(name) {
  const svgs = {
    darrow: `
      
      <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M4.5 6.75L9 11.25L13.5 6.75" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  
  
      `,

    rExclamation: `
      
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_8992_82564)">
  <path d="M10.0003 18.3346C14.6027 18.3346 18.3337 14.6037 18.3337 10.0013C18.3337 5.39893 14.6027 1.66797 10.0003 1.66797C5.39795 1.66797 1.66699 5.39893 1.66699 10.0013C1.66699 14.6037 5.39795 18.3346 10.0003 18.3346Z" stroke="#888888" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10 13.3333V10" stroke="#888888" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10 6.66797H10.0083" stroke="#888888" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
  <clipPath id="clip0_8992_82564">
  <rect width="20" height="20" fill="white"/>
  </clipPath>
  </defs>
  </svg>
  
  
      `,

    grcheckbtn: `
      
      <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.5 0C5 0 0.5 4.5 0.5 10C0.5 15.5 5 20 10.5 20C16 20 20.5 15.5 20.5 10C20.5 4.5 16 0 10.5 0ZM8.5 15L3.5 10L4.91 8.59L8.5 12.17L16.09 4.58L17.5 6L8.5 15Z" fill="#0EB975"/>
</svg>
  
      `,
      rcrossicon: `
      
      <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M18 9C18 13.9705 13.9705 18 9 18C4.02943 18 0 13.9705 0 9C0 4.02943 4.02943 0 9 0C13.9705 0 18 4.02943 18 9ZM6.27267 6.27269C6.53627 6.00908 6.96365 6.00908 7.22727 6.27269L9 8.04537L10.7726 6.2727C11.0363 6.0091 11.4637 6.0091 11.7273 6.2727C11.9909 6.5363 11.9909 6.9637 11.7273 7.22727L9.95454 9L11.7273 10.7726C11.9909 11.0363 11.9909 11.4637 11.7273 11.7273C11.4637 11.9909 11.0363 11.9909 10.7726 11.7273L9 9.95463L7.22727 11.7273C6.96368 11.9909 6.53629 11.9909 6.27269 11.7273C6.00908 11.4637 6.00908 11.0363 6.27269 10.7727L8.04537 9L6.27267 7.22727C6.00906 6.96368 6.00906 6.53629 6.27267 6.27269Z" fill="#9E1616"/>
</svg>

      `,
      share: `
    
    <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.33301 5.9987V1.9987C1.33301 1.63051 1.63149 1.33203 1.99967 1.33203H9.99967C10.3679 1.33203 10.6663 1.63051 10.6663 1.9987V9.9987C10.6663 10.3669 10.3679 10.6654 9.99967 10.6654H5.99967M3.40708 5.9987H5.99967M5.99967 5.9987V8.5913M5.99967 5.9987L1.33301 10.6654" stroke="#4A545E" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


    `,
    shareIconbtn: `
    
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.577 11.5099V16.5876C15.577 17.0365 15.3987 17.467 15.0813 17.7844C14.7638 18.1018 14.3333 18.2801 13.8844 18.2801H4.57537C4.12648 18.2801 3.69597 18.1018 3.37855 17.7844C3.06114 17.467 2.88281 17.0365 2.88281 16.5876V7.2785C2.88281 6.8296 3.06114 6.39909 3.37855 6.08168C3.69597 5.76426 4.12648 5.58594 4.57537 5.58594H9.65305" stroke="#292929" stroke-width="1.82796" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.0352 3.04297H18.1128V8.12065" stroke="#292929" stroke-width="1.82796" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.80469 12.352L18.1138 3.04297" stroke="#292929" stroke-width="1.82796" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


    `,
      favIcon: `
      
      <svg
              class="  "
              width="18"
              height="18"
              viewBox="0 0 16 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.9987 1.33398L10.0587 5.50732L14.6654 6.18065L11.332 9.42732L12.1187 14.014L7.9987 11.8473L3.8787 14.014L4.66536 9.42732L1.33203 6.18065L5.9387 5.50732L7.9987 1.33398Z"
                stroke="currentColor"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

      `,
    GoodStock: `
      
      <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.74258 2.13781L6.30371 1.30255C6.95493 0.94862 7.63447 0.66548 8.34232 0.453125L8.6113 1.35917C7.97424 1.55737 7.33717 1.8122 6.74258 2.13781Z" fill="#B0B0B0"/>
<path d="M3.68538 4.65968L2.94922 4.06508C3.4164 3.4988 3.92605 2.97499 4.49233 2.50781L5.10108 3.24398C4.57728 3.66869 4.11009 4.15003 3.68538 4.65968Z" fill="#B0B0B0"/>
<path d="M1.86015 8.13915L0.954102 7.87017C1.1523 7.19063 1.42128 6.52525 1.74689 5.90234L2.58216 6.34121C2.28486 6.90749 2.04419 7.51624 1.86015 8.13915Z" fill="#B0B0B0"/>
<path d="M11.5 22C5.42664 22 0.5 17.0592 0.5 11C0.5 10.6744 0.514157 10.3488 0.542471 10.0373L1.49099 10.1223C1.44852 10.4054 1.43436 10.7027 1.43436 11C1.43436 16.5495 5.95045 21.0656 11.5 21.0656C17.0495 21.0656 21.5656 16.5495 21.5656 11C21.5656 5.45045 17.0495 0.934363 11.5 0.934363C11.2169 0.934363 10.9337 0.94852 10.6506 0.962677L10.5656 0.028314C10.8771 0.014157 11.1885 0 11.5 0C17.5734 0 22.5 4.9408 22.5 11C22.5 17.0592 17.5734 22 11.5 22Z" fill="black"/>
<path d="M11.4978 16.6045C9.09116 16.6045 6.9676 15.0614 6.20312 12.7538L7.23659 12.4141C7.85949 14.2686 9.57249 15.5144 11.4978 15.5144C13.4232 15.5144 15.1362 14.2686 15.7591 12.4141L16.7926 12.768C16.0281 15.0614 13.9045 16.6045 11.4978 16.6045Z" fill="#039860"/>
<path d="M8.39714 9.75131C9.19465 9.75131 9.84116 9.1048 9.84116 8.3073C9.84116 7.50979 9.19465 6.86328 8.39714 6.86328C7.59963 6.86328 6.95312 7.50979 6.95312 8.3073C6.95312 9.1048 7.59963 9.75131 8.39714 9.75131Z" fill="#039860"/>
<path d="M14.5983 9.75131C15.3958 9.75131 16.0423 9.1048 16.0423 8.3073C16.0423 7.50979 15.3958 6.86328 14.5983 6.86328C13.8008 6.86328 13.1543 7.50979 13.1543 8.3073C13.1543 9.1048 13.8008 9.75131 14.5983 9.75131Z" fill="#039860"/>
</svg>


      `,
      BadStock: `
      
      <?xml version="1.0" encoding="utf-8"?>
<svg width="24" height="24" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#B0B0B;}
	.st112{fill:#ED1C24;} /* Red for the mouth */
	.st2{fill:#ED1C24;} /* Red for the eyes */
</style>
<g>
	<g>
		<g>
			<path class="st0" d="M4.64,1.74L4.33,1.15C4.79,0.9,5.27,0.7,5.77,0.55l0.19,0.64C5.51,1.33,5.06,1.51,4.64,1.74z"/>
		</g>
		<g>
			<path class="st0" d="M2.48,3.52L1.96,3.1c0.33-0.4,0.69-0.77,1.09-1.1l0.43,0.52C3.11,2.82,2.78,3.16,2.48,3.52z"/>
		</g>
		<g>
			<path class="st0" d="M1.19,5.98L0.55,5.79C0.69,5.31,0.88,4.84,1.11,4.4L1.7,4.71C1.49,5.11,1.32,5.54,1.19,5.98z"/>
		</g>
		<g>
			<path class="st0" d="M8,15.77c-4.29,0-7.77-3.49-7.77-7.77c0-0.23,0.01-0.46,0.03-0.68l0.67,0.06C0.9,7.58,0.89,7.79,0.89,8
				c0,3.92,3.19,7.11,7.11,7.11s7.11-3.19,7.11-7.11S11.92,0.89,8,0.89c-0.2,0-0.4,0.01-0.6,0.02L7.34,0.25
				C7.56,0.24,7.78,0.23,8,0.23c4.29,0,7.77,3.49,7.77,7.77S12.29,15.77,8,15.77z"/>
		</g>
	</g>
	<g>
		<g>
			<path class="st112" d="M11.01,11.7C10.57,10.39,9.36,9.51,8,9.51c-1.36,0-2.57,0.88-3.01,2.19l-0.73-0.25C4.8,9.83,6.3,8.74,8,8.74
				c1.7,0,3.2,1.09,3.74,2.72L11.01,11.7z"/>
		</g>
		<g>
			<g>
				<circle class="st112" cx="5.81" cy="6.1" r="1.02"/>
			</g>
			<g>
				<circle class="st112" cx="10.19" cy="6.1" r="1.02"/>
			</g>
		</g>
	</g>
</g>
</svg>


      `,
      AlmostGoodStock: `
      
      <?xml version="1.0" encoding="utf-8"?>
<svg width="24" height="24"  version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
	.st01{fill:#B0B0B0;}
	.st3{fill-rule:evenodd;clip-rule:evenodd;fill:#B0B0B0;}
	.st4{fill:#B0B0B0;}
<style type="text/css">
	.st01{fill:#7A859;}
	.st3{fill-rule:evenodd;clip-rule:evenodd;fill:#B3D10B}
	.st4{fill:#B3D10B;}
</style>
<g>
	<g>
		<g>
			<path class="st1" d="M4.64,1.74L4.33,1.15C4.79,0.9,5.27,0.7,5.77,0.55l0.19,0.64C5.51,1.33,5.06,1.51,4.64,1.74z"/>
		</g>
		<g>
			<path class="st01" d="M2.48,3.53L1.96,3.1c0.33-0.4,0.69-0.77,1.09-1.1l0.43,0.52C3.11,2.82,2.78,3.16,2.48,3.53z"/>
		</g>
		<g>
			<path class="st01" d="M1.19,5.98L0.55,5.79C0.69,5.31,0.88,4.84,1.11,4.4L1.7,4.71C1.49,5.11,1.32,5.54,1.19,5.98z"/>
		</g>
		<g>
			<path class="st01" d="M8,15.77c-4.29,0-7.77-3.49-7.77-7.77c0-0.23,0.01-0.46,0.03-0.68l0.67,0.06C0.9,7.58,0.89,7.79,0.89,8
				c0,3.92,3.19,7.11,7.11,7.11s7.11-3.19,7.11-7.11S11.92,0.89,8,0.89c-0.2,0-0.41,0.01-0.6,0.02L7.34,0.25
				C7.56,0.24,7.78,0.23,8,0.23c4.29,0,7.77,3.49,7.77,7.77S12.29,15.77,8,15.77z"/>
		</g>
	</g>
	<g>
		<g>
			<g>
				<circle class="st3" cx="5.81" cy="6.1" r="1.02"/>
			</g>
			<g>
				<circle class="st3" cx="10.19" cy="6.1" r="1.02"/>
			</g>
		</g>
	</g>
	<g>
		<rect x="4.62" y="10.26" class="st4" width="6.75" height="0.77"/>
	</g>
</g>
</svg>


      `,
      AverageStock: `
      
      <?xml version="1.0" encoding="utf-8"?>
<svg width="24" height="24"  version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
	.st01{fill:#B0B0B0;}
	.st3{fill-rule:evenodd;clip-rule:evenodd;fill:#B0B0B0;}
	.st4{fill:#B0B0B0;}
<style type="text/css">
	.st01{fill:#7A859;}
	.st3{fill-rule:evenodd;clip-rule:evenodd;fill:#B3D10B}
	.st4{fill:#B3D10B;}
</style>
<g>
	<g>
		<g>
			<path class="st1" d="M4.64,1.74L4.33,1.15C4.79,0.9,5.27,0.7,5.77,0.55l0.19,0.64C5.51,1.33,5.06,1.51,4.64,1.74z"/>
		</g>
		<g>
			<path class="st01" d="M2.48,3.53L1.96,3.1c0.33-0.4,0.69-0.77,1.09-1.1l0.43,0.52C3.11,2.82,2.78,3.16,2.48,3.53z"/>
		</g>
		<g>
			<path class="st01" d="M1.19,5.98L0.55,5.79C0.69,5.31,0.88,4.84,1.11,4.4L1.7,4.71C1.49,5.11,1.32,5.54,1.19,5.98z"/>
		</g>
		<g>
			<path class="st01" d="M8,15.77c-4.29,0-7.77-3.49-7.77-7.77c0-0.23,0.01-0.46,0.03-0.68l0.67,0.06C0.9,7.58,0.89,7.79,0.89,8
				c0,3.92,3.19,7.11,7.11,7.11s7.11-3.19,7.11-7.11S11.92,0.89,8,0.89c-0.2,0-0.41,0.01-0.6,0.02L7.34,0.25
				C7.56,0.24,7.78,0.23,8,0.23c4.29,0,7.77,3.49,7.77,7.77S12.29,15.77,8,15.77z"/>
		</g>
	</g>
	<g>
		<g>
			<g>
				<circle class="st3" cx="5.81" cy="6.1" r="1.02"/>
			</g>
			<g>
				<circle class="st3" cx="10.19" cy="6.1" r="1.02"/>
			</g>
		</g>
	</g>
	<g>
		<rect x="4.62" y="10.26" class="st4" width="6.75" height="0.77"/>
	</g>
</g>
</svg>


      `,
  };

  return svgs[name] || "";
}
